import React from "react";
import SolImage from '../../Assets/Images/SolutionsImage.svg'
import { Link } from "react-router-dom";
const SolutionSection = ()=>{

    return(
        <section className="SolutionSection Center">
            <div className="container CustomContainer">
                <div className="row">
                    
                    <div className="col-lg-6  col-md-6  SolutionSectionTextCol">
                        <h1 className="SolutionHeader">Solution</h1>
                        <span>As part of the MIND 
corporation, our subsidiaries represent 
specialized branches that cater to diverse 
sectors within the industry. Each subsidiary 
operates independently under the MIND 
umbrella, focusing on unique aspects 
such as media innovations, information 
technology, and consumer services. These 
entities collaborate synergistically to 
uphold MIND›s overarching mission while 
contributing distinct expertise to their 
respective fields, collectively embodying a 
versatile and comprehensive approach to 
business excellence</span>
                        <Link to='/solutions' className="btn btn-danger NavContactBtn ">Show more</Link>
                    
                    </div>
                    <div className="col-lg-6  col-md-6  SolutionSectionImageCol">
                        <img src={SolImage} width="100%" alt="" />
                    </div>

                </div>
            </div>
        </section>
    );
}
export default SolutionSection;