import React from "react";
import ClientsSlider from "../Sliders/ClientsSlider/ClientsSlider";
const ClientsSection =()=>{
    return(
        <section className="ClientsSection">
            <div className="container">
                <div className="ServicesHeader">
                    <h1>Clients</h1>
                    <span className="ServicesDescription">We proudly work with a wide range of businesses, delivering tailored solutions that enhance their success. <br /> From improving operations to engaging customers, our clients trust us to make a difference.</span>

                </div>
            </div>

            {/* <ServicesSlider/> */}
            <ClientsSlider/>
        </section>
    );
}
export default ClientsSection;