import React from "react";
import Image from '../../Assets/Images/NewsFirstSectionImage.svg'
const NewsFirstSection = ()=>{
    return(
        <section className="AboutFirstSection">
            <div className="container CustomContainer">
                <div className="row AboutPageRow">
                    <div className="col-lg-7 AboutPageCol">
                        <h1 className="AboutPageHeader">News</h1>
                        <div className="UnderAboutHr"></div>
                        <span className="FirstAboutSpan">
                        Stay updated with the latest news and announcements from our company. From product launches to exciting collaborations, find out how we're innovating and making an impact in our industry. Check back regularly for updates on our journey.
                        </span>
                    </div>
                    <div className="col-lg-5 AboutPageImage">
                        <img src={Image} width="80%" alt="" />
                        
                    </div>
                </div>
                
            </div>
        </section>
    );
}
export default NewsFirstSection;