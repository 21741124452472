import React from "react";
import ServicesFirstSection from "../../Components/Services/ServicesFirstSection";
import './Services.css'
import Footer from "../../Components/Footer/Footer";
const Services = ()=>{
    return(
        <div className="Services">
            <ServicesFirstSection/>
            <Footer/>
        </div>
    );
}
export default Services;
