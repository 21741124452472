import ihoneyherb from './../../Assets/Images/News/ihoneyherb.jpeg';
import Marketing from './../../Assets/Images/News/Marketing.jpeg';
import KiddyCorner2 from './../../Assets/Images/News/KiddyCorner2.jpeg';
import NewLocation from './../../Assets/Images/News/NewLocation.jpeg';
import RedCastleFM from './../../Assets/Images/News/RedCastleFM.jpg';
import Shashty from './../../Assets/Images/News/Shashty.jpg';
import MisrHospital from './../../Assets/Images/News/MisrHospital.jpg';
import EasyCare from './../../Assets/Images/News/EasyCare.jpg';
import magicservices from './../../Assets/Images/News/Magic Services Website.png';
import EgyptEconomicForum from './../../Assets/Images/News/Egypt Economic Forum.jpg';
import SportsMate from './../../Assets/Images/News/Sports Mate.png';
import RedCorn from './../../Assets/Images/News/RedCorn.jpg';
import RedCorn2 from './../../Assets/Images/News/RedCorn2.jpeg';
import RamadanIftar from './../../Assets/Images/News/RamadanIftar.jpg';
import AppMedic from './../../Assets/Images/News/AppMedic.png';
import AlamWaraq from './../../Assets/Images/News/AlamWaraq.jpg';
import Doc from './../../Assets/Images/News/Doc.jpeg';
import OurSocialMediaDirector from './../../Assets/Images/News/Our Social Media Director.jpg';
import ChatCora from './../../Assets/Images/News/ChatCora.jpg';
const NewsData = [
  
      {
        id: 1,
        title: "Our latest Marketing & Social Media Solutions for Kiddy Corner",
        description: "We proudly present our latest client “kiddy corner” to work on *their* marketing strategy and social media management .",
        image:Marketing,
        tags:["#MIND","#kiddycorner","#marketing" , "#socialmedia"]
      },
      {
        id: 2,
        title: "Coverage Of Kiddy Corner’s Graduation Party",
        description: "MIND Team have successfully and proudly finished the coverage of kiddy Corner’s graduation party",
        image:KiddyCorner2,
        tags:["#MIND","#kiddycorner","#event","#coverage"]
      },
      {
        id: 3,
        title: "Our Latest E-Commerce Solution",
        description: `MIND team have successfully delivered the E-Commerce solutions ( Website & Mobile App) for ” IHONEYHERB ” in UAE `,
        image:ihoneyherb,
        tags:["#Gulf_Region ","#IHONEYHERB" , "#MINND_Holding"]
      },
      {
        id: 4,
        title: "Save the date 1-1-2024✨",
        description: `Address: 8 Mohammed Tawfik Diab, Nasr City, Cairo, Egypt .
        A team of experienced professionals is at your service.
        Can’t wait to Visit us at our new office!`,
        image:NewLocation,
        tags:["#MINND_Holding"]
      },
      {
        id: 5,
        title: "Our latest website masterpiece for #RedCastleFM",
        description: ` www.redcastle-fm.com
        For Inquiries Visit: www.mindholding.net`,
        image:RedCastleFM,
        tags:["#WebDevelopment" , "#MINND_Holding"]
      },
      {
        id: 6,
        title: "Our latest mobile app for #Shashty.tv",
        description: `Entertainment platform to keep tracking favourite shows ,matches and celebrities
        For Inquiries Visit: www.mindholding.net`,
        image:Shashty,
        tags:["#MobileDevelopment" , "#MINND_Holding"]
      },
      {
        id: 7,
        title: "Our latest website masterpiece for #MisrHospital",
        description: `www.misrhospital.org
        For Inquiries Visit: www.mindholding.net`,
        image:MisrHospital,
        tags:["#WebDevelopment" , "#MINND_Holding"]
      },
      {
        id: 8,
        title: "EasyCare",
        description: `#EasyCare
#إيزي_كير خدمة جديدة بنقدمها للمستشفيات والعيادات
لتسهيل وتعزيز التواصل بين الاطباء والمرضى عن بعد من خلال الموبايل ،
◙ إيه هى مميزات أبلكيشن إيزي كير ؟

– سهولة تحميل الابلكيشن بأسم (المستشفى / العيادة) من المنصات المختلفة
– بناء قاعدة بيانات لكل عميل عن طريق تسجيل الدخول بإشتراك
– سهولة عملية إختيار الطبيب والتخصص في الحصول على الإستشارة
– سهولة إختيار وتحديد التوقيت المناسب للإستشارة
– تنوع طرق الدفع حسب رغبة العملاء
– إمكانية إجراء إستشارات بمكالمات فيديو من خلال الابلكيشن
– يمكن للمريض تحميل أي وثائق خلال الجلسة وعرضها على الطبيب
– سهولة الرجوع للتاريخ المرضي للعميل في استشارات سابقة

بالاضافة إلى:
↔ تقديم الابلكيشن لتقارير كاملة لإدارة المستشفى أو العيادة
↔ عدد المرضى
↔ عدد المواعيد
↔ عدد الجلسات
↔ التقارير المالية لكل مريض / طبيب

إيزي كير نقله جديدة ومتطورة في خدمة تقديم الإستشارات الطبية

لمزيد من المعلومات زوروا موقعنا:
www.mindholding.net
ولمزيد من الاستفسارات:
تواصلوا معانا من خلال الرسائل 🙂
`,
        image:EasyCare,
        tags:["#Mobile_Development" , "#MINND_Holding"]
      },
      {
        id: 9,
        title: "Magic Services Website",
        description: `because Innovative minds think alike
Our latest website masterpiece for #MagicServicesFM 

?
“Housekeeping, Cleaning and maintenance Service”
→ www.magicservices-fm.com`,
        image:magicservices,
        tags:[]
      },
      {
        id: 10,
        title: "Egypt Economic Forum",
        description: `Mrs.Nora Sadek
Business Development Manager at Mind Participating in the 11th Egypt Economic Forum
At Nile Ritz Carlton Hotel Saturday 23rd of March 2019`,
        image:EgyptEconomicForum,
        tags:[]
      },
      {
        id: 11,
        title: "Sports Mate",
        description: `Sports Mate`,
        image:SportsMate,
        tags:[]
      },
      {
        id: 12,
        title: "RedCon booth 1",
        description: `MIND team have successfully delivered booth for “RedCon”
        installed at “landmark hotel”
        redconcon.com
        `,
        image:RedCorn,
        tags:[]
      },
      {
        id: 13,
        title: "RedCon booth 2",
        description: `🙂
Glad to serve our client #RedCon
for 2nd time ?

MIND team have successfully delivered booth for “RedCon”
installed at “Cairo ict”`,
        image:RedCorn2,
        tags:[]
      },
      {
        id: 14,
        title: "Ramadan IFTAR",
        description: `Ramadan IFTAR gathering with family is beautiful but “MIND” Gathering is far away from beautiful, it’s spectacular.
        Best team deserves the best IFTAR day in RAMADAN`,
        image:RamadanIftar,
        tags:["#Ramadan_Nights_2018"]
      },
      {
        id: 15,
        title: "AppMedic Project",
        description: `Congratulations MIND team for successfully winning and delivering the “AppMedic” Website & Its Services ●
→  https://www.appmedic.net/
Keep the good work ?`,
        image:AppMedic,
        tags:[]
      },
      {
        id: 16,
        title: "Alam Waraq",
        description: `The program seeks to bridge the gap between the reader and the writer and presents our vision of the most important books and novels in brief
        Presentation: Professor / Mona Maher – Board member of the story club`,
        image:AlamWaraq,
        tags:[]
      },
      {
        id: 17,
        title: "Documentary video for CARE International – Egypt. – 1st.Feb",
        description: `The project of integrating social accountability in the urgent program for labor-intensive employment.
Again, a successful story, MIND have succeeded to Film, Produce and deliver a documentary video for CARE International – Egypt.
Thank you for all the team who made this project came to light.`,
        image:Doc,
        tags:[]
      },
      {
        id: 18,
        title: "Our Social Media Director",
        description: `MIND is welcoming back Mr. Mahmoud Azzazy, one of MIND’s main assets since 2011 with our main founder Mr. Shereif Hashem

He is back as a “Social Media Director”

Since 2011 he did a great job in being an actor, digital producer.

if you don’t know who is Mahmoud Azzazy
here’s some previous work of his:

——
MIND’s previous work

Radio Director for “Tahrir FM”
Plateau Manager
Coordination & Control of Pre-Production & Post Production
———–
– Digital producer at “GTv”
Projects:

El 3elm wel emaw – Hesham Mansour
Gar7i Show – Ibrahim El Garhi
Meen Da?
Shekl Tani
90 Sanya
Alwan El Teif
Spoofs
& Others
———–

– Produced one of The Planet’s best shows “Vignette”

———-
As an actor, he performed:

Movie: “سمير و شهير و بهير” Role: “عبدالحليم”
Movie: “ورقة شفرة” Role: “شربينو”
Series: “الداعية” Role: “احمد عنتر”

———-
Other online work:

Oppo Mobile’s Ad
Clorox Ad
Online campaign Coca Cola

Welcome back on board Mr. Mahmoud 🙂`,
        image:OurSocialMediaDirector,
        tags:[]
      },
      {
        id: 19,
        title: "Chat Kora Program",
        description: `Chat Kora Program Shooting at MIND plateau`,
        image:ChatCora,
        tags:[]
      },
]


export default NewsData;