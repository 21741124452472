    import React, { useState } from 'react';
    import './Gallery.css';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import { faChevronLeft, faChevronRight, faPlay, faTimes, faVideo } from '@fortawesome/free-solid-svg-icons';

    const ITEMS_PER_PAGE = 9;

    const VideoGallery = ({ videos }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedVideo, setSelectedVideo] = useState(null);

    const totalPages = Math.ceil(videos.length / ITEMS_PER_PAGE);

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const selectedVideos = videos.slice(startIndex, startIndex + ITEMS_PER_PAGE);

    // Handling pagination
    const handleNextPage = () => {
        setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : prevPage));
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
    };

    // Open video in modal
    const openVideo = (videoId) => {
        setSelectedVideo(videoId);
    };

    // Close modal
    const closeVideo = () => {
        setSelectedVideo(null);
    };

    return (
        <div className="container CustomContainer">
        <div className="HeaderWorkSamples">
            <span>Work samples</span>
        </div>
        <div className="gallery-container ">
            {selectedVideos.map((video, index) => (
            <div key={index} className="gallery-item">
                {/* YouTube thumbnail */}
                <img
                src={`https://img.youtube.com/vi/${video}/hqdefault.jpg`}
                alt={`Video ${index}`}
                className="gallery-image"
                onClick={() => openVideo(video)}
                style={{ cursor: 'pointer' }}
                />
                <FontAwesomeIcon icon={faPlay} className="play-icon" />

            </div>
            ))}
            {/* Pagination */}
            <div className="pagination">
            <span onClick={handlePrevPage} style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faChevronLeft} />
            </span>
            {Array.from({ length: totalPages }, (_, index) => (
                <span
                key={index}
                className={index + 1 === currentPage ? 'active' : ''}
                onClick={() => setCurrentPage(index + 1)}
                style={{ cursor: 'pointer' }}
                >
                {index + 1}
                </span>
            ))}
            <span onClick={handleNextPage} style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faChevronRight} />
            </span>
            </div>
        </div>

        {/* Video Modal/Overlay */}
        {selectedVideo && (
            <div className="video-overlay">
            <div className="video-gallery-container">
                <iframe
                width="100%"
                height="500px"
                src={`https://www.youtube.com/embed/${selectedVideo}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                ></iframe>
                
            </div>
            <span className="close-icon" onClick={closeVideo}>
                <FontAwesomeIcon icon={faTimes} />
                </span>
            </div>
        )}
        </div>
    );
    };

    export default VideoGallery;
