import React, { useEffect, useState } from "react";
import SolutionsFirstSection from '../../Components/Solutions/SolutionsFirstSection';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './Solutions.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import AllClasses from '../../Assets/Images/AllClasses.png';
import SolutionCard from "../../Components/Solutions/SolutionCard";
import AllClassesImage1 from '../../Assets/Images/Solutions/AllClassesImage1.svg';
import AllClassesImage2 from '../../Assets/Images/Solutions/AllClassesImage2.png';
import AllClassesImage3 from '../../Assets/Images/Solutions/AllClassesImage3.png';
import SolutionShow1 from "../../Components/Solutions/SolutionShow1";
import SolutionShow2 from "../../Components/Solutions/SolutionShow2";
import Infancia from '../../Assets/Images/Solutions/INFANCIA_LOGO.webp'
import InfanciaImage1 from '../../Assets/Images/Solutions/InfanciaImage1.svg'
import InfanciaImage2 from '../../Assets/Images/Solutions/InfanciaImage2.png'
import InfanciaImage3 from '../../Assets/Images/Solutions/InfanciaImage3.jpg'
import SolutionShow3 from "../../Components/Solutions/SolutionShow3";
import Footer from "../../Components/Footer/Footer";
const CustomPrevButton = ({ onClick, Class }) => (
    <button className={`Center CustomPrevBtn ${Class}`} onClick={onClick}>
        <FontAwesomeIcon icon={faChevronLeft} />
    </button>
);

const CustomNextButton = ({ onClick, Class }) => (
    <button className={`Center CustomNextBtn ${Class}`} onClick={onClick}>
        <FontAwesomeIcon icon={faChevronRight} />
    </button>
);

const Solutions = () => {
    const [selectedSolution, setSelectedSolution] = useState(1);
    const [selectedSolutionData, setSelectedSolutionData] = useState(null);

    const solutions = [
        {
            id: 1,
            icon: AllClasses,
            header: 'All Classes',
            text: 'All Classes connects online communities with various sections that empower users through events, offers, jobs, and more. Discover the potential of your community with our innovative features.',
            section1Image: AllClassesImage1,
            section1Text: "All Classes is a comprehensive platform designed for online communities, offering a variety of features to enhance member engagement and collaboration. Whether it's accessing exclusive offers, organizing events, or participating in charitable initiatives, All Classes provides a unique experience tailored to each community's needs. The platform also includes a jobs board, a directory of members, and a marketplace for buying and selling items within the community. With its user-friendly interface and wide range of tools, All Classes connects members in meaningful ways and fosters a strong sense of belonging.",
            section2Image: AllClassesImage2,
            section2Text: 'All Classes offers a complete solution for closed communities, combining powerful features like event management, exclusive offers, and member directories. It also enables users to create and share job opportunities, start fundraising campaigns, and buy or sell products. With chat rooms and integrated social connections, the platform fosters active community engagement while providing seamless access to valuable tools that enhance the overall member experience.',
            section3Image: AllClassesImage3,
            section3Text: `All Classes is a versatile app designed for closed communities, offering various tools that cater to members' unique needs. Users can access exclusive offers, create and attend events, and participate in charity drives. A detailed directory allows members to connect by searching names, jobs, locations, and more. Job postings and buy-and-sell features help members grow their networks and find new opportunities. The platform also includes links to community groups, medical insurance options, and online shopping.

For sports enthusiasts, members can find partners, book courts, or hire coaches. Chat rooms allow users to share hobbies and interests. All Classes also features a rewarding system, messaging capabilities, and a robust admin control panel for tracking statistics and managing the community.

This customizable app elevates collaboration and interaction among community members, providing everything needed to enhance group engagement.`,
        },
        {
            id: 2,
            icon: Infancia,
            header: 'Infancia',
            text: "Manage your nursery through an efficient mobile app system that lets you create and modify classes, assign kids to classes, and add/remove teachers. Parents get instant updates about their kids' activities.",
            section1Image: InfanciaImage1,
            section1Text: "Infancia is a daily reporting mobile app tailored for nurseries. It allows real-time tracking of children’s activities, health updates, and educational progress. Designed to meet the needs of parents and nursery administrators, the platform streamlines communication and operational tasks. With detailed reports on meals, behavior, and academic lessons, Infancia enhances parents' ability to stay informed. Its user-friendly interface ensures easy access to essential features such as messaging, nursery profiles, and child performance tracking. Infancia is a fully branded solution available on both iOS and Android, bringing convenience and clarity to child care.",
            section2Image: InfanciaImage2,
            section2Text: 'Infancia offers nurseries a comprehensive solution for managing daily activities and parent communication. Features include a control panel for staff to add and modify weekly meal plans, academic subjects, and assign children to classes. Parents receive notifications and reports on their child’s daily activities, health updates, and meal consumption. Through its messaging system, Infancia enables direct, secure communication between parents and nursery staff, promoting timely updates and feedback. The app also supports social media integration, providing parents with links to nursery profiles, policies, and contact information.',
            section3Image: InfanciaImage3,
            section3Text: `Infancia provides a robust dashboard where nursery administrators can manage everything from meal plans to lesson schedules. Teachers can send posts, newsletters, and push notifications to specific classes or the entire nursery. Parents receive real-time updates on their child’s progress and can access academic lessons and health records. Infancia also supports a rating and review system for parents to provide feedback on the nursery’s services. The platform allows for the easy addition of child profiles, teacher assignments, and a seamless parent-nursery interaction, ensuring transparency and trust between both parties.`,
        },
    ];

    const handleOnSelect = (id) => {
        const sol = solutions.find(i => i.id === id);
        setSelectedSolution(id);
        setSelectedSolutionData(sol || {}); 
    };
    useEffect(()=>{
        setSelectedSolutionData(solutions[0]);
    },[])

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1300 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1300, min: 768 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 768, min: 585 },
            items: 2
        },
        smobile: {
            breakpoint: { max: 585, min: 350 },
            items: 1
        },
        xsmobile: {
            breakpoint: { max: 350, min: 0 },
            items: 1
        }
    };

    return (
        <div className="Solutions">
            <SolutionsFirstSection />
            <div className="FirstAboutSectionBorder"> </div>
            <section className="SliderSolutionSections">
                <div className="container CustomContainer">
                    <div className="SliderSolutionText">
                        <span>As part of the MIND 
corporation, our subsidiaries represent 
specialized branches that cater to diverse 
sectors within the industry. Each subsidiary 
operates independently under the MIND 
umbrella, focusing on unique aspects 
such as media innovations, information 
technology, and consumer services. These 
entities collaborate synergistically to 
uphold MIND›s overarching mission while 
contributing distinct expertise to their 
respective fields, collectively embodying a 
versatile and comprehensive approach to 
business excellence.</span>
                    </div>
                </div>
                {/* Slider */}
                <div className="container CustomContainer">
                    <div className="slider-container ServicesSlider SolutionSliderContainer">
                        <Carousel
                            responsive={responsive}
                            infinite={true}
                            autoPlay={false}
                            autoPlaySpeed={2000}
                            draggable={true}
                            customLeftArrow={<CustomPrevButton Class="" />}
                            customRightArrow={<CustomNextButton Class="" />}
                        >
                            {solutions.map((solution) => (
                                <div className="SerciceCardContainer" key={solution.id}>
                                    <SolutionCard
                                        id={solution.id}
                                        icon={solution.icon}
                                        Header={solution.header}
                                        text={solution.text}
                                        IsSelected={selectedSolution === solution.id}
                                        onSelect={handleOnSelect}
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
                {selectedSolutionData && (
                    <SolutionShow1
                        key={selectedSolutionData.id}
                        Header={selectedSolutionData.header}
                        Image={selectedSolutionData.section1Image}
                        Text={selectedSolutionData.section1Text}
                    />
                )}

                
            </section>
            {selectedSolutionData&&
                    <SolutionShow2
                        Image={selectedSolutionData.section2Image}
                        Text={selectedSolutionData.section2Text}
                    />
                }
            {selectedSolutionData&&
                <SolutionShow3
                    Image={selectedSolutionData.section3Image}
                    Text={selectedSolutionData.section3Text}
                />
            }

            <Footer/>
        </div>
    );
}

export default Solutions;
