import React from "react";
const ClientsFirstSection = ()=>{
    return(
        <div className="ClientsFirstSection">
            <div className="ServicesHeadingContainer">
                    <span className="HeaderClients">Our Clients</span>
                    <div className="UnderLineServices"></div>
                    <span className="AfterLineText">We are proud to collaborate with a diverse range of businesses and organizations, each benefiting from our tailored solutions. From global enterprises to local innovators, our clients trust us to deliver exceptional results that drive their success. Whether it's optimizing operations, enhancing customer engagement, or creating seamless experiences, we partner with industry leaders to make a difference.</span>
                </div>
        </div>
    );
}
export default ClientsFirstSection;