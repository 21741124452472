import image0 from '../src/Assets/Images/New folder/1.png';
import image1 from '../src/Assets/Images/New folder/11.png';
import image2 from '../src/Assets/Images/New folder/2.png';
import image3 from '../src/Assets/Images/New folder/3.png';
import image5 from '../src/Assets/Images/New folder/4.png';
import image6 from '../src/Assets/Images/New folder/5.png';
import image7 from '../src/Assets/Images/New folder/appmedic.png';
import image8 from '../src/Assets/Images/New folder/BB.png';
import image9 from '../src/Assets/Images/New folder/CARE_HORIZ_2c1-3 (1).png';
import image10 from '../src/Assets/Images/New folder/CARE_HORIZ_2c1-3.png';
import image11 from '../src/Assets/Images/New folder/cosmos-logo-final-e1543399065358.jpg';
import image12 from '../src/Assets/Images/New folder/cropped-optima-final-logo-01-200x62-1.png';
import image13 from '../src/Assets/Images/New folder/ECKF.png';
import image14 from '../src/Assets/Images/New folder/EGY-ROWING.png';
import image15 from '../src/Assets/Images/New folder/El-moez-logo-01-200x150-1.png';
import image16 from '../src/Assets/Images/New folder/Feed.png';
import image17 from '../src/Assets/Images/New folder/FISB.png';
import image18 from '../src/Assets/Images/New folder/HC.png';
import image19 from '../src/Assets/Images/New folder/KW.png';
import image20 from '../src/Assets/Images/New folder/MEP.png';
import image21 from '../src/Assets/Images/New folder/OR.png';
import image22 from '../src/Assets/Images/New folder/Orignal-Logo-OSC-200x113-1.png';
import image23 from '../src/Assets/Images/New folder/Redcon-Orignal-logo-200x67-1.png';
import image24 from '../src/Assets/Images/New folder/tawala.png';
import image25 from '../src/Assets/Images/New folder/Untitled-1.png';
import image26 from '../src/Assets/Images/New folder/Untitled-10-1.jpg';
import image27 from '../src/Assets/Images/New folder/Untitled-10.png';
import image28 from '../src/Assets/Images/New folder/Untitled-11-1.jpg';
import image29 from '../src/Assets/Images/New folder/Untitled-11.png';
import image30 from '../src/Assets/Images/New folder/Untitled-12-1-1.jpg';
import image31 from '../src/Assets/Images/New folder/Untitled-12-1.jpg';
import image32 from '../src/Assets/Images/New folder/Untitled-12.png';
import image33 from '../src/Assets/Images/New folder/Untitled-13-1.jpg';
import image34 from '../src/Assets/Images/New folder/Untitled-13.png';
import image35 from '../src/Assets/Images/New folder/Untitled-15-.jpg';
import image36 from '../src/Assets/Images/New folder/Untitled-16.png';
import image37 from '../src/Assets/Images/New folder/Untitled-18.png';
import image38 from '../src/Assets/Images/New folder/Untitled-19-1 (1).png';
import image39 from '../src/Assets/Images/New folder/Untitled-19-1.png';
import image40 from '../src/Assets/Images/New folder/Untitled-2.png';
import image41 from '../src/Assets/Images/New folder/Untitled-20-1 (1).png';
import image42 from '../src/Assets/Images/New folder/Untitled-20-1.png';
import image43 from '../src/Assets/Images/New folder/Untitled-21-1 (1).png';
import image44 from '../src/Assets/Images/New folder/Untitled-21-1.png';
import image45 from '../src/Assets/Images/New folder/Untitled-22-1 (1).png';
import image46 from '../src/Assets/Images/New folder/Untitled-22-1.png';
import image47 from '../src/Assets/Images/New folder/Untitled-23-1.png';
import image48 from '../src/Assets/Images/New folder/Untitled-24.png';
import image49 from '../src/Assets/Images/New folder/Untitled-25-1 (1).png';
import image50 from '../src/Assets/Images/New folder/Untitled-25-1 (2).png';
import image51 from '../src/Assets/Images/New folder/Untitled-25-1.png';
import image52 from '../src/Assets/Images/New folder/Untitled-26-1 (1).png';
import image53 from '../src/Assets/Images/New folder/Untitled-26-1.png';
import image54 from '../src/Assets/Images/New folder/Untitled-27-1 (1).png';
import image55 from '../src/Assets/Images/New folder/Untitled-27-1.png';
import image56 from '../src/Assets/Images/New folder/Untitled-28-1 (1).png';
import image57 from '../src/Assets/Images/New folder/Untitled-28-1.png';
import image58 from '../src/Assets/Images/New folder/Untitled-29-1 (1).png';
import image59 from '../src/Assets/Images/New folder/Untitled-29-1.png';
import image60 from '../src/Assets/Images/New folder/Untitled-3.png';
import image61 from '../src/Assets/Images/New folder/Untitled-30-1 (1).png';
import image62 from '../src/Assets/Images/New folder/Untitled-30-1 (2).png';
import image63 from '../src/Assets/Images/New folder/Untitled-30-1.png';
import image64 from '../src/Assets/Images/New folder/Untitled-31-1 (1).png';
import image65 from '../src/Assets/Images/New folder/Untitled-31-1.png';
import image66 from '../src/Assets/Images/New folder/Untitled-32.png';
import image67 from '../src/Assets/Images/New folder/Untitled-33.png';
import image68 from '../src/Assets/Images/New folder/Untitled-34.-jpg-1-200x200.jpg';
import image69 from '../src/Assets/Images/New folder/Untitled-34.-jpg-1.jpg';
import image70 from '../src/Assets/Images/New folder/Untitled-34.png';
import image71 from '../src/Assets/Images/New folder/Untitled-35.png';
import image72 from '../src/Assets/Images/New folder/Untitled-36.png';
import image73 from '../src/Assets/Images/New folder/Untitled-37.png';
import image74 from '../src/Assets/Images/New folder/Untitled-38.png';
import image75 from '../src/Assets/Images/New folder/Untitled-39.png';
import image76 from '../src/Assets/Images/New folder/Untitled-4.png';
import image77 from '../src/Assets/Images/New folder/Untitled-40.png';
import image78 from '../src/Assets/Images/New folder/Untitled-41.png';
import image79 from '../src/Assets/Images/New folder/Untitled-42.png';
import image80 from '../src/Assets/Images/New folder/Untitled-43.png';
import image81 from '../src/Assets/Images/New folder/Untitled-44.png';
import image82 from '../src/Assets/Images/New folder/Untitled-45.png';
import image83 from '../src/Assets/Images/New folder/Untitled-5.png';
import image84 from '../src/Assets/Images/New folder/Untitled-56-.jpg';
import image85 from '../src/Assets/Images/New folder/Untitled-6.jpg';
import image86 from '../src/Assets/Images/New folder/Untitled-7-2.jpg';
import image87 from '../src/Assets/Images/New folder/Untitled-7.png';
import image88 from '../src/Assets/Images/New folder/Untitled-8-1.jpg';
import image89 from '../src/Assets/Images/New folder/Untitled-8.png';
import image90 from '../src/Assets/Images/New folder/Untitled-9-1.jpg';
import image91 from '../src/Assets/Images/New folder/Untitled-9.png';

const images = [image0, image1, image2, image3, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15, image16, image17, image18, image19, image20, image21, image22, image23, image24, image25, image26, image27, image28, image29, image30, image31, image32, image33, image34, image35, image36, image37, image38, image39, image40, image41, image42, image43, image44, image45, image46, image47, image48, image49, image50, image51, image52, image53, image54, image55, image56, image57, image58, image59, image60, image61, image62, image63, image64, image65, image66, image67, image68, image69, image70, image71, image72, image73, image74, image75, image76, image77, image78, image79, image80, image81, image82, image83, image84, image85, image86, image87, image88, image89, image90, image91];

export default images;
